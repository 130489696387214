var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "agent-form" },
    [
      _c("el-header", [
        _c(
          "div",
          {
            staticClass: "logo-container",
            style: { color: _vm.currentTheme.logoTextColor },
          },
          [
            _c("img", {
              attrs: { src: require("@/assets/images/logo1.png"), alt: "" },
            }),
            _c("span", [_vm._v("Bunker Chain")]),
          ]
        ),
      ]),
      _vm.isValid
        ? _c(
            "div",
            { staticClass: "container" },
            [
              _vm._m(0),
              _c("p", { staticClass: "one-tip" }, [
                _vm._v(
                  "Please fill in the following information to complete the registration."
                ),
              ]),
              _c(
                "el-form",
                {
                  ref: "userForm",
                  attrs: {
                    "label-position": "top",
                    "label-width": "230px",
                    model: _vm.userForm,
                    rules: _vm.rules,
                  },
                },
                [
                  _c(
                    "el-row",
                    { attrs: { gutter: 40 } },
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "Email", prop: "email" } },
                            [
                              _c("el-input", {
                                attrs: { disabled: "" },
                                model: {
                                  value: _vm.userForm.email,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.userForm, "email", $$v)
                                  },
                                  expression: "userForm.email",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "Salutation",
                                prop: "salutation",
                              },
                            },
                            [
                              _c(
                                "el-select",
                                {
                                  staticStyle: { width: "100%" },
                                  model: {
                                    value: _vm.userForm.salutation,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.userForm, "salutation", $$v)
                                    },
                                    expression: "userForm.salutation",
                                  },
                                },
                                _vm._l(
                                  [
                                    "Mr.",
                                    "Mrs.",
                                    "Miss",
                                    "Dr.",
                                    "Ms.",
                                    "Prof.",
                                    "Rev.",
                                    "Lady.",
                                    "Sir.",
                                    "Capt.",
                                  ],
                                  function (item) {
                                    return _c("el-option", {
                                      key: item,
                                      attrs: { value: item },
                                    })
                                  }
                                ),
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-row",
                    { attrs: { gutter: 40 } },
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: { label: "First Name", prop: "firstName" },
                            },
                            [
                              _c("el-input", {
                                model: {
                                  value: _vm.userForm.firstName,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.userForm, "firstName", $$v)
                                  },
                                  expression: "userForm.firstName",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "Last Name", prop: "lastName" } },
                            [
                              _c("el-input", {
                                model: {
                                  value: _vm.userForm.lastName,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.userForm, "lastName", $$v)
                                  },
                                  expression: "userForm.lastName",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-row",
                    { attrs: { gutter: 40 } },
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "Password", prop: "password" } },
                            [
                              _c("el-input", {
                                attrs: {
                                  type: "password",
                                  "show-password": "",
                                },
                                model: {
                                  value: _vm.userForm.password,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.userForm, "password", $$v)
                                  },
                                  expression: "userForm.password",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "Confirm password",
                                prop: "confirmPassword",
                              },
                            },
                            [
                              _c("el-input", {
                                attrs: {
                                  type: "password",
                                  "show-password": "",
                                },
                                model: {
                                  value: _vm.userForm.confirmPassword,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.userForm,
                                      "confirmPassword",
                                      $$v
                                    )
                                  },
                                  expression: "userForm.confirmPassword",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-row",
                    { attrs: { gutter: 40 } },
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "H/p Numbers",
                                prop: "homePhoneNumber",
                              },
                            },
                            [
                              _c("phone-input", {
                                model: {
                                  value: _vm.userForm.homePhoneNumber,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.userForm,
                                      "homePhoneNumber",
                                      $$v
                                    )
                                  },
                                  expression: "userForm.homePhoneNumber",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "Office Phone Number",
                                prop: "officePhoneNumber",
                              },
                            },
                            [
                              _c("phone-input", {
                                model: {
                                  value: _vm.userForm.officePhoneNumber,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.userForm,
                                      "officePhoneNumber",
                                      $$v
                                    )
                                  },
                                  expression: "userForm.officePhoneNumber",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-row",
                    { attrs: { gutter: 40 } },
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 24 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "Designation",
                                prop: "designation",
                              },
                            },
                            [
                              _c("el-input", {
                                model: {
                                  value: _vm.userForm.designation,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.userForm, "designation", $$v)
                                  },
                                  expression: "userForm.designation",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "btns" },
                [
                  _c(
                    "el-button",
                    { staticClass: "btn", on: { click: _vm.resetForm } },
                    [_vm._v(_vm._s(_vm.$t("reset")))]
                  ),
                  _c(
                    "el-button",
                    {
                      staticClass: "btn",
                      attrs: { type: "primary", loading: _vm.submitLoading },
                      on: { click: _vm.submitForm },
                    },
                    [_vm._v("submit")]
                  ),
                ],
                1
              ),
              _c("p", { staticClass: "one-tip" }, [
                _vm._v("Many thanks and have a great day ahead !"),
              ]),
              _c("p", { staticClass: "one-tip" }, [
                _vm._v(
                  "By uploading and accepting the conditions or uploading any documents, you confirm and agree to the binding usage of Digital agreements of Bunkerchain Pte Ltd, if you have received this e-mail by mistake, please inform the user immediately or send a technical request to support@bunkerchain.io. Bunkerchain Pte Ltd reserve all rights, for a copy of our terms & conditions, please visit our website at www.bunkerchain.io, or request with the user administrator. Please note that any views or opinions presented in this email are solely those of the author and do not necessarily represent those of the company. Finally, the recipient should check this email and any attachments for the presence of viruses. The company accepts no liability for any damage caused by any virus transmitted by this email."
                ),
              ]),
            ],
            1
          )
        : _c("div", { staticClass: "no-valid" }, [
            _vm._v(" " + _vm._s(_vm.$t("pageExpired")) + " "),
          ]),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "email-detail" }, [
      _c("p", [_vm._v("Dear Sirs,")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }